import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';

import SVG from '../assets/svg';

const Copytoclipboard = ({
  copyText = '',
  children,
}: {
  copyText: string;
  children?: React.ReactNode;
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard text={copyText} onCopy={handleCopy}>
      <Wrapper>
        <Button
          src={isCopied ? SVG.check : SVG.copyBtn}
          alt={isCopied ? 'Copied' : 'Copy'}
        />
        {children && <div>{children}</div>}
      </Wrapper>
    </CopyToClipboard>
  );
};

export default Copytoclipboard;

const Wrapper = styled.div`
  display: inline-flex;
`;

const Button = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
`;
