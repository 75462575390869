import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Errors } from '../const';
import { Routes } from '../routes';

type InputPropsType = {
  value: string;
  errorMsg: string;
};

type OnInputChangeHanlder = (
  setStateChange: Dispatch<SetStateAction<InputPropsType>>,
) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;

type OnInputBlurHanlder = (
  state: InputPropsType,
  setStateChange: Dispatch<SetStateAction<InputPropsType>>,
) => () => void;

export const handleInputChange: OnInputChangeHanlder = setStateChange => e => {
  const { value } = e.target;

  setStateChange(prevState => ({
    ...prevState,
    value,
  }));
};

export const handleInputBlur: OnInputBlurHanlder =
  (state, setStateChange) => () => {
    if (state.errorMsg) {
      setStateChange(prevState => ({
        ...prevState,
        errorMsg: '',
      }));
    }
  };

export const isEmptyInput = (
  inputState: InputPropsType | string,
  setStateChange: Dispatch<SetStateAction<InputPropsType>>,
  errorMsg?: string,
) => {
  if (!inputState || typeof inputState === 'string') {
    if (!inputState || inputState.trim() === '') {
      setStateChange(prevState => ({
        ...prevState,
        errorMsg: errorMsg ?? Errors.BlankField,
      }));
      return true;
    }

    return false;
  }

  if (inputState.value.trim() === '') {
    setStateChange(prevState => ({
      ...prevState,
      errorMsg: errorMsg ?? Errors.BlankField,
    }));
    return true;
  }

  return false;
};

export const isEmptyMultiSelect = (
  selected: number[],
  setStateChange: Dispatch<SetStateAction<InputPropsType>>,
  errorMsg?: string,
) => {
  if (selected.length === 0) {
    setStateChange(prevState => ({
      ...prevState,
      errorMsg: errorMsg ?? Errors.ChouseSelectOptions,
    }));
    return true;
  }

  return false;
};

export const apiFieldErrorHandler = (
  field: string | string[] | undefined,
  setStateChange: Dispatch<SetStateAction<InputPropsType>>,
  errorMsg?: string,
) => {
  if (field && typeof field === 'string') {
    setStateChange(prevState => ({
      ...prevState,
      errorMsg: errorMsg ?? field,
    }));
    return true;
  }

  if (field && typeof field === 'object') {
    setStateChange(prevState => ({
      ...prevState,
      errorMsg: errorMsg ?? field[0],
    }));
    return true;
  }

  return false;
};

export const isEmail = (email: string) => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const dataURItoImageFile = (dataURI: string): Promise<Blob> =>
  fetch(dataURI)
    .then(r => r.blob())
    .then(
      blobFile => new File([blobFile], 'fileName.png', { type: 'image/png' }),
    );

export const getPagesCount = (totalPages: number, limit: number) =>
  Math.ceil(totalPages / limit);

export const routeTemplate = (
  path: string,
  template: { [key: string]: string },
) => {
  const matchResults = path.match(/:(\w+)/g);
  if (matchResults) {
    let newPath = path;
    matchResults.forEach(matchResult => {
      const keyName = matchResult.substring(1);
      newPath = newPath.replace(matchResult, template[keyName] || '');
    });
    return newPath;
  }
  return '';
};

export const copyUrl = ({ projectId, groupId }: any) => {
  const url = routeTemplate(Routes.projectBranchNda, {
    projectId: projectId.toString(),
    branchId: groupId.toString(),
  });
  return `${window.location.origin + url}`;
};
