import add from './Add.svg';
import addMember from './Add-member.svg';
import addMemberCardBg from './Add-member-card-bg.svg';
import addNewUser from './Add-user.svg';
import addedUser from './Added-to-project.svg';
import addProject from './AddProject.svg';
import archiveNda from './ArchiveNda.svg';
import attachment from './Attachment.svg';
import avtar from './Avtar.svg';
import bell from './Bell.svg';
import billingButton from './Billing.svg';
import billingInfo from './BillingInfo.svg';
import check from './Check.svg';
import checkGreen from './Check-green.svg';
import checkWhite from './Check-white.svg';
import circleCheck from './Circle-check.svg';
import comment from './Comment.svg';
import copyBtn from './Copy.svg';
import create from './Create.svg';
import cross from './Cross.svg';
import crossBlue from './Cross-blue.svg';
import crown from './Crown.svg';
import date from './Date.svg';
import deleteImg from './Delete.svg';
import diamond from './Diamond.svg';
import disabled from './Disabled.svg';
import document from './Document.svg';
import done from './done.svg';
import downArrow from './Down-arrow.svg';
import downloadNDA from './download-nda.svg';
import email from './Email.svg';
import emailWhite from './Email-white.svg';
import errorMessage from './error-message.svg';
import eventMessage from './event-message.svg';
import expand from './Expand.svg';
import eye from './Eye.svg';
import facebook from './Facebook.svg';
import file from './File.svg';
import fileDocument from './FileDocument.svg';
import fileDocumentBg from './FileDocumentBg.svg';
import fileWhite from './FileWhite.svg';
import folderSkyBg from './FolderSkyBg.svg';
import gallery from './Gallery.svg';
import goBack from './Go-back-button.svg';
import hideEye from './HideEye.svg';
import info from './Info.svg';
import inviteAndTrack from './Invite-and-track.svg';
import left from './Left.svg';
import linkedIn from './LinkedIn.svg';
import lock from './Lock.svg';
import logout from './Logout.svg';
import lookNDA from './look-nda.svg';
import medal from './Medal.svg';
import mention from './Mention.svg';
import menuBar from './Menu-bar.svg';
import messageQuote from './MessageQuote.svg';
import minusDarkBlue from './MinusDarkBlue.svg';
import minusWhite from './MinusWhite.svg';
import mobile from './Mobile.svg';
import openArrow from './OpenArrow.svg';
import openArrowWhite from './OpenArrowWhite.svg';
import opportunities from './Opportunities.svg';
import pencil from './Pencil.svg';
import pencilDraw from './Pencil-draw.svg';
import pencilDrawBg from './Pencil-draw-bg.svg';
import pencilGrey from './PencilGrey.svg';
import pencilSky from './PencilSky.svg';
import plus from './Plus.svg';
import plusDarkBlue from './PlusDarkBlue.svg';
import plusWhite from './PlusWhite.svg';
import point from './Point.svg';
import process from './Process.svg';
import profileAvtar from './ProfileAvtar.svg';
import projectLogo from './Project.svg';
import projectFolder from './projectFolder.svg';
import projectFolderCircle from './projectFolderCircle.svg';
import projectListingBg from './ProjectListingBg.svg';
import projectButton from './Projects-profile.svg';
import projectYellowFolder from './projectYellowFolder.svg';
import recall from './Recall.svg';
import recallBg from './Recall-bg.svg';
import rejected from './Rejected.svg';
import rejectedRed from './RejectedRed.svg';
import reportsButton from './Reports.svg';
import resend from './Resend.svg';
import resendRed from './ResendRed.svg';
import rightArrow from './RightArrow.svg';
import rocket from './Rocket.svg';
import safe from './Safe.svg';
import savingTimes from './SavingTimes.svg';
import search from './Search.svg';
import sendAndNegotiate from './Send-and-negotiate.svg';
import sent from './Sent.svg';
import sent2 from './Sent-2.svg';
import settings from './Settings.svg';
import shield from './Shield.svg';
import signed from './Signed.svg';
import signUp from './SignUp.svg';
import smileyEmoji from './SmileyEmoji.svg';
import successMessage from './success-message.svg';
import templatesButton from './Templates.svg';
import fromTemplate from './Templates-2.svg';
import twitter from './Twitter.svg';
import unarchiveNda from './UnarchiveNda.svg';
import updateMessage from './update-message.svg';
import upload from './Upload-file.svg';
import uploadImage from './Upload-image.svg';
import uploadSecond from './Upload-second.svg';
import userIcon from './UserIcon.svg';
import view from './View.svg';
import whitePlus from './White-plus.svg';
import wordExcel from './WordExcel.svg';
import wordExcelBg from './WordExcelBg.svg';

const SVG = {
  add,
  addMember,
  addMemberCardBg,
  addProject,
  attachment,
  avtar,
  info,
  search,
  openArrow,
  addNewUser,
  addedUser,
  billingButton,
  billingInfo,
  checkGreen,
  checkWhite,
  copyBtn,
  date,
  deleteImg,
  disabled,
  downloadNDA,
  expand,
  eye,
  folderSkyBg,
  file,
  fileWhite,
  fromTemplate,
  fileDocument,
  fileDocumentBg,
  lookNDA,
  logout,
  openArrowWhite,
  pencil,
  pencilSky,
  pencilGrey,
  point,
  profileAvtar,
  projectLogo,
  projectFolderCircle,
  projectListingBg,
  projectButton,
  projectYellowFolder,
  plusWhite,
  rejected,
  rejectedRed,
  reportsButton,
  resend,
  resendRed,
  sent,
  sent2,
  settings,
  signed,
  templatesButton,
  upload,
  uploadSecond,
  errorMessage,
  eventMessage,
  successMessage,
  updateMessage,
  cross,
  projectFolder,
  goBack,
  userIcon,
  archiveNda,
  unarchiveNda,
  done,
  wordExcel,
  wordExcelBg,
  document,
  plus,
  view,
  comment,
  rightArrow,
  smileyEmoji,
  medal,
  mention,
  crown,
  diamond,
  gallery,
  left,
  email,
  hideEye,
  signUp,
  lock,
  whitePlus,
  uploadImage,
  recall,
  emailWhite,
  linkedIn,
  twitter,
  facebook,
  shield,
  mobile,
  messageQuote,
  rocket,
  menuBar,
  inviteAndTrack,
  opportunities,
  safe,
  create,
  sendAndNegotiate,
  circleCheck,
  savingTimes,
  downArrow,
  crossBlue,
  recallBg,
  pencilDraw,
  pencilDrawBg,
  bell,
  process,
  minusWhite,
  minusDarkBlue,
  plusDarkBlue,
  check,
};

export default SVG;
