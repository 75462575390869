import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import styled from 'styled-components';

import {
  ColorType,
  contacts as apiContacts,
  ContactType,
  dashboard as apiDashboard,
  DashboardGroupType,
  OneNdaType,
  OneProjectType,
  projects as apiProjects,
} from '../../../../api';
import { Signer } from '../../../../api/signership';
import SVG from '../../../../assets/svg';
import {
  Copytoclipboard,
  HidenBoxHovered,
  Modal,
  ModalBox,
  RecipientStatus,
} from '../../../../atoms';
import { formatDateMode } from '../../../../const';
import { copyUrl } from '../../../../helpers/functions';
import { ActionsButton, SendMailForm } from '../../../../organisms';
import { Routes } from '../../../../routes';
import { BoxShadow, Colors, rem } from '../../../../themes';
import { ColorDisc } from './ColorChanger';
import RecipientActions, {
  Button,
  Container,
  OneAction,
} from './RecipientActions';
import SubTableHeader from './SubTableHeader';
import { ContextTable, TableTitlesType } from './Table';

export type DataCell = Column<TableTitlesType>;

type Props = {
  projectId: number;
  groupId: number;
  groupName: string;
  nda: OneNdaType | null;
  groupData: TableTitlesType[];
  isSignerOfProject: boolean;
  colorStatus: ColorType;
  projectName: string | undefined;
  index: number;
  projectBranch: DashboardGroupType[] | undefined;
};

function SubTable({
  projectId,
  groupId,
  groupName,
  nda,
  groupData,
  isSignerOfProject,
  colorStatus,
  projectName,
  index,
  projectBranch,
}: Props) {
  const { updateDashboard } = useContext(ContextTable);
  const history = useHistory();
  const [isShowRows, setIsShowRows] = useState(
    !!isSignerOfProject || index === 0,
  );
  const [isHidePopover, setIsHidePopover] = useState(false);

  const [allContacts, setAllContacts] = useState<ContactType[]>([]);
  const [oneProject, setOneProject] = useState<OneProjectType | null>(null);
  const [Allsigner, setAllSigner] = useState<Signer[]>([]);
  const [isOpenResendModal, setIsOpenResendModal] = useState(false);
  const [signerIdForResend, setSignerIdForResend] = useState<
    number | undefined
  >();
  const [groupUsersIdsForResend, setGroupUsersIdsForResend] = useState<
    number[] | undefined
  >();

  useEffect(() => {
    if (!isOpenResendModal) {
      setSignerIdForResend(undefined);
      setGroupUsersIdsForResend(undefined);
      return;
    }

    apiContacts.getAllContact().then(({ data }) => setAllContacts(data));
    apiProjects
      .getOneProject(Number(projectId))
      .then(({ data }) => setOneProject(data));
    apiDashboard.getAllDashboardInfo(Number(projectId)).then(({ data }) =>
      setAllSigner(
        data.results
          .filter(i => i.id === Number(groupId))
          .map(i => i.signership.map(o => o.signer))
          .flat(),
      ),
    );
  }, [isOpenResendModal, projectId, groupId]);

  useEffect(() => {
    isHidePopover && setIsHidePopover(false);
  }, [isHidePopover]);

  const showNdaForSigner = () => {
    history.push(
      `${Routes.projects}/${projectId}/group/${nda?.projectBranch}/nda`,
    );
  };

  const getColorStatusLabel = (color: string | null | undefined) => {
    switch (color) {
      case 'red':
        return 'Rejected';
      case 'green':
        return 'Completed';
      case 'yellow':
        return 'Pending';
      default:
        return '';
    }
  };

  const colors =
    groupData.find(item => item.color.color === 'yellow') ||
    groupData.find(item => item.color.color === 'red') ||
    groupData.find(item => item.color.color === 'green');

  const columns: DataCell[] = useMemo(
    () => [
      {
        accessor: 'company',
        Cell: ({ value }) => <TableCell width="100px" value={value} />,
      },
      {
        accessor: 'name',
        Cell: ({ value }) => (
          <>
            <TableRow>
              <TableCell width="160px" value={value} />
              <Copytoclipboard
                copyText={`${copyUrl({ projectId, groupId })}`}
              />
            </TableRow>
          </>
        ),
      },
      {
        accessor: 'email',
        Cell: ({ value }) => <TableCell value={value} />,
      },
      {
        accessor: 'color',
        Cell: ({ value }) => (
          <StatusData color={value.color ?? 'red'}>
            <ColorDisc color={value.color ?? 'red'} />
            <span style={{ marginLeft: '10px' }}>
              {getColorStatusLabel(value.color)}
            </span>
          </StatusData>
        ),
      },
      {
        accessor: 'status',
        Cell: ({ value }) => <RecipientStatus status={value} />,
      },
      {
        accessor: 'date',
        Cell: ({ value }) => {
          const formatDate =
            value !== ''
              ? `${format(new Date(value), formatDateMode.default)}`
              : '-';

          return <TableDateCell width="90px" value={formatDate} />;
        },
      },
      {
        accessor: 'actions',
        Cell: ({ value }) => (
          <ActionsButton isDisabled={!value.nda} isHidePopover={isHidePopover}>
            {isSignerOfProject ? (
              <Container>
                <OneAction onClick={showNdaForSigner}>
                  <Button src={SVG.lookNDA} alt="Look NDA" />
                  <StyledButton>Look up NDA</StyledButton>
                </OneAction>
              </Container>
            ) : (
              <RecipientActions
                onResendClick={() => {
                  setIsHidePopover(true);
                  setIsOpenResendModal(true);
                  setSignerIdForResend(value.userId);
                }}
                projectId={projectId}
                groupId={groupId}
              />
            )}
          </ActionsButton>
        ),
      },
      {
        accessor: 'toggleOpen',
        Cell: () => <></>,
      },
    ],
    // colorStatus must be in dependencies array for updating all dashboard after change color in group
    /* eslint-disable-next-line */
    [isHidePopover, isSignerOfProject, colorStatus, updateDashboard],
  );

  const { rows, prepareRow } = useTable({
    columns,
    data: groupData,
  });

  return (
    <>
      <Modal
        modalIsOpen={isOpenResendModal}
        setModalIsOpen={setIsOpenResendModal}
      >
        <ModalBox
          onCloseButtonClick={() => setIsOpenResendModal(false)}
          title="Enter Recipient info"
        >
          <SendMailForm
            projectBranchId={groupId}
            ndaName={nda?.name ?? ''}
            allContacts={allContacts}
            summary={oneProject?.summary ?? ''}
            allsigner={Allsigner}
            signerId={signerIdForResend}
            groupUsers={groupUsersIdsForResend}
            closeModal={() => {
              updateDashboard();
              setIsOpenResendModal(false);
            }}
            projectName={projectName}
          />
        </ModalBox>
      </Modal>
      <SubTableHeader
        isShowRows={isShowRows}
        setIsShowRows={setIsShowRows}
        projectId={projectId}
        groupId={groupId}
        groupName={groupName}
        nda={nda}
        groupUsers={groupData.map(signer => signer.actions.userId)}
        isSignerOfProject={isSignerOfProject}
        colorStatus={colorStatus}
        ndaSigning={colors?.color.color}
        resendNdaForGroup={() => {
          setIsOpenResendModal(true);
          setGroupUsersIdsForResend(groupData.map(user => user.actions.userId));
        }}
        projectBranch={projectBranch}
      />
      {isShowRows &&
        rows.map(row => {
          prepareRow(row);
          return (
            <tr data-value="recipient" {...row.getRowProps()}>
              {row.cells.map(cell => (
                <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
              ))}
            </tr>
          );
        })}
    </>
  );
}

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const TableCell = styled(HidenBoxHovered)`
  padding: 10px 0;
  max-width: 200px;
  white-space: nowrap;
  text-align: left;
  font-size: ${rem(10)};
`;

const TableDateCell = styled(TableCell)`
  text-align: center;
`;

const TD = styled.td`
  padding: 0;
  height: 60px;
  background-color: rgba(96, 96, 96, 0.06);
  border-right: 1px solid rgba(2, 48, 97, 0.1);

  &:first-child {
    & > * {
      text-align: left;
    }
  }
  & > * {
    margin: 0 auto;
  }
`;

const StatusData = styled.div<{ color: ColorType }>`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 60px;
  padding: 10px;
  text-align: center;

  color: ${props => {
    switch (props.color) {
      case 'red':
        return Colors.DarkRed;
      case 'yellow':
        return Colors.Yellow;
      case 'green':
        return Colors.Green;
      default:
        return Colors.WeakGrey;
    }
  }};
`;

export const StyledButton = styled.button<{ disabled?: boolean }>`
  height: 30px;
  min-width: 84px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  background-color: ${Colors.White};
  color: ${Colors.DarkGrey};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  & > *:not(:last-child) {
    margin-right: 5px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const BaseButton = styled(StyledButton)`
  padding: 5px 15px;
  box-shadow: ${BoxShadow.Base};
  border: 1px solid ${Colors.LightBlue};
`;

export default SubTable;
